<i18n>
{
  "en": {
    "api-explainer": "To integrate LiveCall Bot into your LINE WORKS Workspace, we require additional API credentials. Please follow the below steps to create the necessary credentials, or provide existing credentials below if you already have them.",
    "open-developer-console": "Open Developer Console",
    "create-client-app-explainer": "Click {0} and enter a descriptive name, e.g. \"LiveCall Bot\". Click {1} to continue.",
    "add-client-app": "Add client app",
    "confirm": "Confirm",
    "client-id-explainer": "Copy the Client ID and enter it below.",
    "client-secret-explainer": "Copy the Client Secret and enter it below.",
    "oauth-scopes": "OAuth Scopes",
    "oauth-scopes-explainer": "Click the OAuth Scopes {0} button and select: {1} Then click {2}.",
    "bot-api-read-and-write": "(Bot API read and write)",
    "user-api-read": "(User API Read)",
    "manage": "Manage",
    "save": "Save",
    "save-client-app-explainer": "Click {0} to finish the client app setup.",
    "service-account-creation-explainer": "Click the Service Account {0} button and confirm the creation of a service account.",
    "service-account-explainer": "Copy the Service Account and enter it below.",
    "get-button": "GET",
    "private-key-explainer": "Click the Private Key {0} button to download the private key file. Select this file below.",
    "issue-button": "issue / re-issue",
    "private-key": "Private Key",
    "domain-id-explainer": "Enter the Domain ID (number) displayed in the LINE WORKS Developer Console below.",
    "continue": "Continue",
    "is-required": "Please enter a value",
    "must-be-numeric": "This value must be numeric",
    "missing-argument-error": "Missing value for {0}.",
    "invalid-credentials-error": "Failed to connect to the LINE WORKS API. Please check your input and secret key file.",
    "bot-registration-error": "An unknown error occurred while trying to register the LiveCall bot. Please try again. For more help, visit our {0}.",
    "support-page": "support page"
  },
  "ja": {
    "api-explainer": "LiveCall for LINE WORKSの利用を開始するには、LINE WORKSとのAPI認証連携が必要です。下記手順にしたがってLINE WORKS Developer Consoleで設定した情報を入力してください。",
    "open-developer-console": "Developer Console を開く",
    "create-client-app-explainer": "{0}をクリックし、アプリ名を入力します。{1}をクリックします。",
    "add-client-app": "アプリの新規追加",
    "confirm": "同意して利用する",
    "client-id-explainer": "Client IDをコピーし下の欄に入力します。",
    "client-secret-explainer": "Client Secretをコピーし下の欄に入力します。",
    "oauth-scopes": "OAuth Scopes",
    "oauth-scopes-explainer": "OAuth Scopesの{0}をクリックし{1}を選択し{2}をクリックします。",
    "bot-api-read-and-write": "（Bot機能の書き込み／読み取り権限）",
    "manage": "管理",
    "save": "保存",
    "save-client-app-explainer": "{0}をクリックし、アプリ情報の設定を完了させます。",
    "service-account-creation-explainer": "Service Accountの{0}をクリックしService Accountを確認します。",
    "service-account-explainer": "Service Accountをコピーし、下の欄に入力します。",
    "get-button": "発行",
    "private-key-explainer": "Private Keyの{0}をクリックし、Private Key ファイルをダウンロードし、下の欄でこのファイルを選択します。",
    "issue-button": "発行/再発行",
    "domain-id-explainer": "LINE WORKS Developer Consoleサイドバー下に表示されているDomain IDを下の欄に入力します。",
    "continue": "進む",
    "is-required": "必ず入力してください",
    "must-be-numeric": "数字である必要があります",
    "missing-argument-error": "{0}が未記入です。再度ご確認ください。",
    "invalid-credentials-error": "API連携に失敗しました。入力内容、アップロードファイルを再度ご確認ください。",
    "bot-registration-error": "LiveCall for LINEWORKS登録中に不明なエラーが発生しました。再度お試しください。解決しない場合{0}をご確認ください。",
    "support-page": "サポートページ"
  }
}
</i18n>

<template>
  <v-container class="px-0">
    <p>{{ $t('api-explainer') }}</p>

    <p>
      <v-btn color="primary" target="_blank" href="https://developers.worksmobile.com/jp/console/openapi/v2/app/list/view">
        {{ $t('open-developer-console') }}
        <v-icon right>mdi-open-in-new</v-icon>
      </v-btn>
    </p>

    <i18n path="create-client-app-explainer" tag="p">
      <sample>{{ $t('add-client-app') }}</sample>
      <sample>{{ $t('confirm') }}</sample>
    </i18n>

    <v-form :disabled="working" v-model="valid">
      <ol>
        <li>
          <h3>Client ID</h3>
          <p>{{ $t('client-id-explainer') }}</p>
          <p>
            <v-text-field outlined
                          label="Client ID"
                          :rules="[requiredRule]"
                          v-model="clientId">
            </v-text-field>
          </p>
        </li>

        <li>
          <h3>Client Secret</h3>
          <p>{{ $t('client-secret-explainer') }}</p>
          <p>
            <v-text-field outlined
                          label="Client Secret"
                          :rules="[requiredRule]"
                          v-model="clientSecret">
            </v-text-field>
          </p>
        </li>

        <li>
          <h3>{{ $t('oauth-scopes') }}</h3>
          <i18n path="oauth-scopes-explainer" tag="p">
            <sample>{{ $t('manage') }}</sample>
            <ul>
              <li>
                <label><input type="checkbox" checked @click.prevent> bot</label> {{ $t('bot-api-read-and-write') }}
              </li>
              <li>
                <label><input type="checkbox" checked @click.prevent> user.read</label> {{ $t('user-api-read') }}
              </li>
            </ul>
            <sample>{{ $t('save') }}</sample>
          </i18n>
        </li>

        <li>
          <h3>{{ $t('save') }}</h3>
          <i18n path="save-client-app-explainer" tag="p">
            <sample>{{ $t('save') }}</sample>
          </i18n>
        </li>

        <li>
          <h3>{{ $t('Service Account') }}</h3>
          <i18n path="service-account-creation-explainer" tag="p">
            <sample>{{ $t('get-button') }}</sample>
          </i18n>
          <p>{{ $t('service-account-explainer') }}</p>
          <p>
            <v-text-field outlined
                          placeholder="...serviceacount@works-123456"
                          label="Service Account"
                          :rules="[requiredRule]"
                          v-model="serviceAccount">
            </v-text-field>
          </p>
          <i18n path="private-key-explainer" tag="p">
            <sample>{{ $t('issue-button') }}</sample>
          </i18n>
          <p>
            <v-file-input outlined
                          :label="$t('private-key')"
                          :rules="[requiredRule]"
                          v-model="privateKey">
            </v-file-input>
          </p>
        </li>

        <li>
          <h3>Domain ID</h3>
          <p>{{ $t('domain-id-explainer') }}</p>
          <p>
            <v-text-field outlined
                          label="Domain ID"
                          :rules="[requiredRule, isNumericRule]"
                          v-model="domainId">
            </v-text-field>
          </p>
        </li>
      </ol>
      <v-alert v-if="error" type="error" elevation="2">
        <i18n :path="error">
          <span v-if="error === 'missing-argument-error'">{{ missingArgument }}</span>
          <span v-else-if="error === 'bot-registration-error'">
            <a href="https://support.livecall.jp/hc/ja/categories/360005370191"
               target="_blank"
               class="text-decoration-none"><v-icon x-small>mdi-open-in-new</v-icon> {{ $t('support-page') }}</a>
          </span>
        </i18n>
      </v-alert>
      <p>
        <v-btn type="submit"
               color="primary"
               @click.prevent="processAPICredentials"
               :disabled="!valid || working"
               :loading="working">
          {{ $t('continue') }}
        </v-btn>
      </p>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: 'SetupApi',
  components: {
    Sample: () => import('./SampleButton')
  },
  data: () => ({
    valid: false,
    working: false,
    domainId: null,
    clientId: null,
    clientSecret: null,
    serviceAccount: null,
    privateKey: null,
    error: null,
    missingArgument: null
  }),
  methods: {
    requiredRule(v) {
      return !!v || this.$t('is-required');
    },
    isNumericRule(v) {
      return /^\d+$/.test(v) || this.$t('must-be-numeric');
    },
    async processAPICredentials() {
      this.working = true;
      this.error = this.missingArgument = null;

      const data = new FormData();
      data.append('client_id', this.clientId);
      data.append('client_secret', this.clientSecret);
      data.append('service_account', this.serviceAccount);
      data.append('private_key', this.privateKey);
      data.append('domain_id', this.domainId);

      /** @type {Response} */
      const response = await this.$api.fetch('/admin/signup', { method: 'POST', body: data });

      if (response.ok) {
        this.$emit('success');
      } else {
        const error = await response.json();
        console.error(error);

        if ('missing' in error) {
          this.error = 'missing-argument-error';
          this.missingArgument = error.missing;
        } else {
          this.error = `${error.error}-error`;
        }
      }

      this.working = false;
    }
  }
}
</script>

<style scoped>

</style>